import { Component, OnInit } from '@angular/core';
import anime from 'animejs/lib/anime.es.js';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(
    private _app: AppComponent
  ) { }

  ngOnInit() {
    if (!this._app.stillLoading) {
      setTimeout(()=>{
        this.pageAnimation();
      }, 50)
    } else {
      setTimeout(()=>{
        this.pageAnimation();
      }, 2000)
    }
  }

  pageAnimation() {
    // var fade_text = document.querySelectorAll('.fade-text');
    // var introduction_section = document.querySelectorAll('.introduction-section p');
    // var title_section = document.querySelectorAll('.title-section p');
    // var tl = anime.timeline({});
    // tl
    // .add({
    //   targets: fade_text,
    //   opacity: [0, 1],
    //   easing: 'linear',
    //   duration: 200,
    // }, 0)
    // .add({
    //   targets: ['.mini-section p'],
    //   translateX: [250, 0],
    //   opacity: [0, 1],
    //   easing: 'easeInOutExpo',
    //   delay: function(el, i, l) {
    //     return i * 100;
    //   },
    //   endDelay: function(el, i, l) {
    //     return (l - i) * 100;
    //   }
    // }, '+=10')
    // .add({
    //   targets: '.page-footer',
    //   opacity: [0, 1],
    //   easing: 'easeInOutExpo'
    // }, '+=100');
  }

  startAnimation($event, content, color) {
    this._app.startAnimation($event, content, color);
  }

  backAnimation($event, content, color) {
    this._app.backAnimation($event, content, color);
  }

}
