import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ExperienceComponent } from './experience/experience.component';
import { HobbyComponent } from './hobby/hobby.component';
import { CgJourneyComponent } from './cg-journey/cg-journey.component';
import { B1HelloWorldComponent } from './hobby/blogs/b1-hello-world/b1-hello-world.component';
import { B2GeomodComponent } from './hobby/blogs/b2-geomod/b2-geomod.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  }, {
    path: 'home',
    component: HomeComponent,
    data: { title: 'Home' }
  }, {
    path: 'experience',
    component: ExperienceComponent,
    data: { title: 'Experience' }
  }, {
    path: 'other',
    component: HobbyComponent,
    data: { title: 'Other' }
  }, {
    path: 'blogs',
    redirectTo: '/other',
    pathMatch: 'full'
  },  {
    path: 'cg-journey',
    component: CgJourneyComponent,
    data: { title: 'CG Journey'}
  }, {
    path: 'blogs/hello-world',
    component: B1HelloWorldComponent,
    data: { title: 'Blog - Hello World'}
  }, {
    path: 'blogs/geomod-notes',
    component: B2GeomodComponent,
    data: { title: 'Blog - Geomod Notes'}
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  declarations: []
})
export class RoutingModule { }
