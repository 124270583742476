import { Component, OnInit, AfterContentInit } from '@angular/core';
import { transition, trigger, query, style, animate, group, animateChild } from '@angular/animations';
import { NavigationCancel, Event, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import * as $ from 'jquery';
import anime from 'animejs/lib/anime.es.js';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    trigger('routerTransition', [
      transition('* <=> *', [
        query(
          ':enter, :leave',
          style({
            position: 'fixed',
            width: '100%'
          })
          ,{ optional: true })
        ,group([
          query(':enter', [
            style({
              // transform: 'translateX(100%)',
              opacity: 0
            }),
            animate(
              '1s ease-out',
              style({
                // transform: 'translateX(0%)',
                opacity: 1
              })
            )
          ], { optional: true }),
          query(':leave', [
            style({
              // transform: 'translateX(0%)',
              opacity: 1
            }),
            animate(
              '0.1s ease-in',
              style({
                // transform: 'translateX(-100%)',
                opacity: 0
              })
            )
          ], { optional: true }),
        ])
      ])
    ])
  ]
})
export class AppComponent implements OnInit, AfterContentInit {
  title = 'app';
  stillLoading: boolean = true;


  constructor(
    private _router: Router
  ) {}

  ngOnInit() {
    this._router.events.subscribe((event: Event) => {
      this.navigationInterceptor(event);
    });
  }

  ngAfterContentInit() {
  }

  private navigationInterceptor(event: Event): void {
    this.testOrigin()
    if (event instanceof NavigationStart) {
    }
    if (event instanceof NavigationEnd) {
      setInterval(()=>{
        this.stillLoading = false;
      }, 2000)
    }
    if (event instanceof NavigationCancel) {
    }
    if (event instanceof NavigationError) {
    }
  }

  testOrigin() {
    origin = location.origin;
    if (origin != "https://keyneoei.dev") {
      window.location.href = "https://keyneoei.dev" + location.pathname;
    }
  }

  startAnimation($event, content, color?) {
    var srcElement = $event['srcElement'];
    if (content == 'title') {
      anime({
        targets: srcElement,
        translateX: 20,
        duration: 400,
        color: color,
        easing: 'easeInQuart'
      });
    } else if (content == 'project') {
      anime({
        targets: srcElement,
        translateX: 20,
        duration: 400,
        easing: 'easeInQuart'
      });
    }
  }

  backAnimation($event, content, color?) {
    var srcElement = $event['srcElement'];
    if (content == 'title' && color != null) {
      anime({
        targets: srcElement,
        translateX: 0,
        duration: 400,
        color: color,
        easing: 'easeOutQuart'
      });
    } else if (content == 'project') {
      anime({
        targets: srcElement,
        translateX: -20,
        duration: 400,
        easing: 'easeOutQuart'
      });
    }
  }
}
