import { Component, OnInit } from '@angular/core';
import anime from 'animejs/lib/anime.es.js';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-experience',
  templateUrl: './experience.component.html',
  styleUrls: ['./experience.component.css']
})
export class ExperienceComponent implements OnInit {

  constructor(
    private _app: AppComponent
  ) { }

  ngOnInit() {
    if (!this._app.stillLoading) {
      setTimeout(()=>{
        this.pageAnimation();
      }, 300)
    } else {
      setTimeout(()=>{
        this.pageAnimation();
      }, 2000)
    }
  }

  pageAnimation() {
    var education_section = document.querySelectorAll('.text-animate');
    var work_section = document.querySelectorAll('.text-animate-2');
    var project_section = document.querySelectorAll('.text-animate-3');
    var github_section = document.querySelectorAll('.text-animate-4');
    var tl = anime.timeline({});
    tl
    .add({
      targets: [education_section, work_section, project_section, github_section],
      translateX: [150, 0],
      opacity: [0, 1],
      duration: 450,
      easing: 'easeInOutCubic',
      delay: function(el, i, l) {
        return i * 40;
      },
      endDelay: function(el, i, l) {
        return (l - i) * 40;
      }
    }, 0)
    .add({
      targets: '.page-footer',
      opacity: [0, 1],
      easing: 'easeInOutExpo'
    }, '+=100');
  }

  educations = [
    {
      title: 'Visual Computing Master Program',
      description: "Saarland Informatic Campus",
      period: "2019 - 2021"
    },
    {
      title: 'Double Degree Computer Science Bachelor Program',
      description: "University of Queensland - Bachelor of Information Technology" +
      "\nUniversity of Indonesia - S.Kom or Bachelor of Computer Science",
      period: "2015 - 2019"
    }
  ]

  achievements = [
    {
      title: '2nd place of Rendering Competition',
      description: 'from Saarland Informatic Campus in Computer Graphic Course (Prof. Dr.-Ing. Philipp Slusallek)',
      period: 'Feb 2020'
    },
    {
      title: 'EAIT Scholar',
      description: "from University of Queensland",
      period: "Dec 2019"
    },
    {
      title: 'Highest Grade Point Average (GPA) Student in International Computer Science Degree',
      description: "from University of Indonesia",
      period: "Aug 2019"
    },
    {
      title: 'Dean’s Commendation for Academic Excellence',
      description: 'from University of Queensland',
      period: 'Mar 2018'
    }
  ]

  experiences = [
    {
      title: 'Computer Vision Researcher',
      company: 'University of Queensland',
      description: '',
      period: 'Nov 2018 - Apr 2019 (6 mos)'
    }, {
      title: 'Software Engineer',
      company: 'Jacques Technologies Brisbane',
      description: '',
      period: 'Mar 2018 - Apr 2019 (1 yr 1 mo)'
    }, {
      title: 'iOS Developer',
      company: 'Jojonomic Indonesia',
      description: '',
      period: 'Jun 2017 - Aug 2017 (3 mos)'
    }, {
      title: 'Discrete Math Teaching Assistant',
      company: 'University of Indonesia',
      description: '',
      period: 'Aug 2016 - Feb 2017 (5 mos)'
    }, {
      title: 'Introduction to Digital System Teaching Assistant',
      company: 'University of Indonesia',
      description: '',
      period: 'Aug 2016 - Feb 2017 (5 mos)'
    }
  ]

  projects = [
    {
      title: 'Graphic (Ray Tracing) Developer in C++',
      project: 'Lost in Tokyo (Ray Trace Rendering Architecture)',
      description: 'from Computer Graphic course (Prof. Dr.-Ing. Philipp Slusallek)',
      period: '',
      git: 'https://github.com/keynekassapa13/lost-in-japan',
      web: 'https://graphics.cg.uni-saarland.de/courses/cg1-2019/RC/oei_web/'
    },
    {
      title: 'Deep Learning Developer (Pytorch)',
      project: 'Weighted bi-GRU & CNN Architecture',
      description: 'from Neural Net course (Prof. Dr. Dietrich Klakow)',
      period: '',
      git: 'https://github.com/keynekassapa13/Weighted-biGRU-CNN',
      web: 'https://medium.com/@keynekassapa13/weighted-bi-gru-cnn-ab6cdbeda77b'
    },
    {
      title: 'OpenGL & C++ Developer',
      project: 'Solar System',
      description: '',
      period: '',
      git: 'https://github.com/keynekassapa13/solar-system',
      web: 'https://medium.com/@keynekassapa13/creating-the-solar-system-opengl-and-c-9d4e4798d759'
    },
    {
      title: 'Deep Learning & Full Stack Developer (Tensorflow & Angular)',
      project: 'Ruka, Non Verbal Communication for Smart Home System using Deep Learning',
      description: '',
      period: '',
      git: 'https://github.com/keynekassapa13/Ruka',
      web: ''
    }, {
      title: 'Scrum Master, Full Stack Developer',
      project: 'Easy Referral',
      description: '',
      period: '',
      git: 'https://github.com/keynekassapa13/EasyReferral',
      web: ''
    }, {
      title: 'Scrum Master, Full Stack Developer',
      project: 'Addressed Style Personalized Report',
      description: '',
      period: '',
      git: '',
      web: ''
    }, {
      title: 'Full Stack Developer (Ruby)',
      project: 'GoMarket C2C Ecommerce',
      description: '',
      period: '',
      git: '',
      web: ''
    }
  ]


}
