import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  template:
  `
  <div class="page-footer">
    <hr>
    <div class="row ">
      <div class="col-md-6">
        <div class="font-qu mb-3">
          <div class="font-80">Other</div>
          <p class="font-la text-bold font-100">
            <a class="link-footer" target="_blank" href="https://github.com/keynekassapa13">Github</a>,
            <a class="link-footer" target="_blank" href="https://www.linkedin.com/in/keynekassapa/">Linkedin</a>,
            <a class="link-footer" target="_blank" href="https://www.behance.net/keynekassapa13">Behance</a>,
            <a class="link-footer" target="_blank" href="https://medium.com/@keynekassapa13">Medium</a>
            <br/>

          </p>

        </div>
      </div>
      <div class="col-md-6">
        <div class="font-qu mb-3">
          <div class="font-80">email</div>
          <p class="font-la text-bold font-100">
            <a href="mailto: keynekassapa13@gmail.com" class="link-footer">
              keynekassapa13@gmail.com
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
  `,
  styles: [
    `
    .page-footer {
      margin-top: 5%;
      opacity: 0;
    }

    hr {
      border: 0.5px solid #212121;
    }
    `
  ]
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
