import { Component, OnInit } from '@angular/core';
import anime from 'animejs/lib/anime.es.js';
import { AppComponent } from '../../../app.component';

@Component({
  selector: 'app-b1-hello-world',
  templateUrl: './b1-hello-world.component.html',
  styleUrls: []
})
export class B1HelloWorldComponent implements OnInit {

  constructor(
    private _app: AppComponent
  ) { }

  ngOnInit() {
    this.pageAnimation()
  }


  pageAnimation() {
    var tl = anime.timeline({});
    tl
    .add({
      targets: '.page-footer',
      opacity: [0, 1],
      easing: 'easeInOutExpo'
    }, '+=100');
  }

  startAnimation($event, content) {
    this._app.startAnimation($event, content);
  }

  backAnimation($event, content) {
    this._app.backAnimation($event, content);
  }

}
