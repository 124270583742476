import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs/Rx';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import anime from 'animejs/lib/anime.es.js';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-hobby',
  templateUrl: './hobby.component.html',
  styleUrls: ['./hobby.component.css']
})
export class HobbyComponent implements OnInit {

  constructor(
    private http: HttpClient,
    private _DomSanitizationService: DomSanitizer,
    private _app: AppComponent
  ) { }

  behance_url = location.origin + "/backend/behance_works";
  medium_posts =  location.origin + "/backend/medium_posts";
  github_projects =  location.origin + "/backend/github_projects";
  // behance_url = 'http://localhost:3000/backend/behance_works';
  // medium_posts = "http://localhost:3000/backend/medium_posts";
  // github_projects = "http://localhost:3000/backend/github_projects";
  projects_design = null;
  posts = null;
  projects = null;

  ngOnInit() {
    this.pageAnimation()
    this.getAPI();
  }

  blogs = [{
      'title': 'My Geometric Modeling Notes',
      'path': './blogs/geomod-notes',
      'date': 'Mon, 7th Dec 2020'
  }, {
      'title': 'Hello World - First Post!',
      'img': 'assets/blogs/1-hello-world/DSC04413-min.JPG',
      'path': './blogs/hello-world',
      'date': 'Wed, 05 Aug 2020'
  }
  ]

  getAPI() {

    let header = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    header.append('responseType','ResponseContentType.Blob');
    this.http.get(
      this.medium_posts,
      { headers: header}
    )
    .pipe(
      // catchError(this.handleError)
    )
    .subscribe(
      (data) => {
        this.posts = data;
        if (data["http_code"] == 200) {
          if (!this._app.stillLoading) {
            setTimeout(()=>{
              this.pageAnimation();
            }, 50)
          } else {
            setTimeout(()=>{
              this.pageAnimation();
            }, 500)
          }
        }
      }
    );

    this.http.get(
      this.github_projects,
      { headers: header}
    )
    .pipe(
      // catchError(this.handleError)
    )
    .subscribe(
      (data) => {
        this.projects = data;
        if (data["http_code"] == 200) {
          if (!this._app.stillLoading) {
            setTimeout(()=>{
              this.pageAnimation();
            }, 50)
          } else {
            setTimeout(()=>{
              this.pageAnimation();
            }, 500)
          }
        }
      }
    );
  }

  pageAnimation() {
    var tl = anime.timeline({});
    tl
    .add({
      targets: '.page-footer',
      opacity: [0, 1],
      easing: 'easeInOutExpo'
    }, '+=100');
  }

  startAnimation($event, content) {
    this._app.startAnimation($event, content);
  }

  backAnimation($event, content) {
    this._app.backAnimation($event, content);
  }

}
