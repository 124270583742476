import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { RoutingModule } from './/routing.module';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ExperienceComponent } from './experience/experience.component';
import { HobbyComponent } from './hobby/hobby.component';
import { FooterComponent } from './footer.component';
import { B1HelloWorldComponent } from './hobby/blogs/b1-hello-world/b1-hello-world.component';
import { B2GeomodComponent } from './hobby/blogs/b2-geomod/b2-geomod.component';
import { CgJourneyComponent } from './cg-journey/cg-journey.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ExperienceComponent,
    HobbyComponent,
    FooterComponent,
    B1HelloWorldComponent,
    B2GeomodComponent,
    CgJourneyComponent,
  ],
  imports: [
    BrowserModule,
    RoutingModule,
    BrowserAnimationsModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
